.guide *,
.monitor *,
.ant-modal-content *,
.ant-drawer-content * {
  font-family: ui-monospace, SFMono-Regular, "SF Mono", Consolas, "Liberation Mono", Menlo, monospace !important;
}

#root {
  height: 100%;
}

.www,
.guide,
.monitor {
  min-width: 1200px;
}

.header.ant-layout-header {
  height: 45px !important;
  background: #202020;
  border-bottom: 1px solid rgba(256, 256, 256, 0.9);
}

.header.ant-layout-header>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white !important;
  margin: 0px auto;
  overflow: hidden;
  height: 45px;
}

.header.ant-layout-header .logo {
  font-size: 24px !important;
  font-weight: 600;
  cursor: pointer;
  font-family: sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header.ant-layout-header .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px !important;
  color: white !important;
  font-weight: 600;
}

.header.ant-layout-header .menu *:hover {
  color: white;
}

.header.ant-layout-header .ant-dropdown-trigger {
  margin-left: 20px;
  height: 45px !important;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.header.ant-layout-header .ant-btn {
  background: transparent;
  color: white;
  margin-left: 10px;
  height: 25px !important;
  border: none;
}

.header.ant-layout-header .ant-btn.default-btn {
  border: 1px solid white;
}

.header.ant-layout-header .ant-btn:hover,
.header.ant-layout-header .ant-btn:focus {
  background: #1890ff;
  color: white;
}

.footer.ant-layout-footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  padding: 0px;
  background: transparent;
}

.monitor .footer.ant-layout-footer {
  flex-direction: column;
  align-items: center;
}

.monitor .ant-layout-sider .ant-menu {
  height: 100%;
}

.monitor .ant-layout-sider .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background-color: #181818 !important;
}

.guide .content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 0;
}

.guide.ant-layout {
  background-color: white !important;
}

.guide.ant-layout,
.monitor.ant-layout {
  height: 100%;
  overflow: hidden;
}

.monitor.ant-layout {
  background-color: #f5f5f5 !important;
}

.monitor .ant-menu {
  border: none;
}

.monitor .ant-menu-inline .ant-menu-item {
  padding-left: 45px !important;
  transition: padding-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.monitor .ant-menu-item.ant-menu-item-selected {
  background: #006eff !important;
  color: white !important;
  transform: scale(1.05);
}

.ant-menu-item:hover {
  background-color: #262f3e !important;
  animation: menu-hover 0.3s ease-in 1 forwards;
}

@keyframes menu-hover {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.ant-menu-item {
  margin: 0px !important;
}

.ant-menu-item .nav-text {
  font-weight: 600 !important;
  color: rgba(256, 256, 256, 0.95);
}

.monitor .ant-menu-inline-collapsed .ant-menu-item {
  padding-left: 16px !important;
  transition: padding-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.monitor .ant-menu-item-selected::after {
  display: none !important;
}

.monitor .ant-menu {
  padding-top: 10px !important;
}

.monitor .ant-menu-item .anticon svg {
  font-size: 15px !important;
  transform: translateY(1px);
  color: white;
}

.monitor .ant-layout-content {
  background-color: white !important;
  padding: 10px;
  overflow: hidden;
  position: relative;
}

.monitor .ant-layout-content .content {
  height: 100%;
  position: relative;
}

.monitor .ant-table-footer .ant-checkbox-wrapper {
  margin-left: 14px !important;
}

.pod {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px !important;
}

.pod p:first-child {
  margin-top: 1em;
  font-size: 18px !important;
  margin-bottom: 12px;
}

.pod .gift {
  color: #f50;
  font-weight: bold;
  font-size: 16px !important;
  margin-left: 2px;
  margin-bottom: 6px !important;
}

.bean-income {
  color: #52c41a;
}

.bean-consume {
  color: #f50;
}

.beans-transaction-types {
  height: 32px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 0px 5px;
  margin-top: 4px;
}

.tag-warning-filled {
  padding: 0 3px;
  background: #f50;
  color: white;
}

.tag-success-line {
  padding: 0 3px;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #b7eb8f;
}

.tag-info-line {
  padding: 0 3px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  color: #1890ff;
}

.ml5 {
  margin-left: 5px;
}

.invitation-area {
  padding: 22px 10px 20px 10px;
  border: 1px solid #91d5ff;
  background: #e6f7ff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.invitation-area .ant-input-group-wrapper {
  width: 625px;
}

.invitation-area .ant-input-wrapper .ant-input-group-addon:nth-child(3) {
  padding: 0px;
}

.invitation-area .ant-input-group-addon button {
  border: none;
  background: transparent;
  padding: 0px 11px;
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
}

.invitation-area .ant-badge {
  margin-top: 15px;
}

.consume-rules .service {
  font-weight: bold;
}

.consume-rules ul {
  padding-left: 15px;
  margin-bottom: -15px;
}

.consume-rules ul li {
  margin-top: 10px;
}

.consume-rules ul li.rule-item:not(:first-child) {
  margin-top: 25px;
}

.dns-records .ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border-width: 0 0 1px 0;
}

.dns-records .record-type-value tbody tr td:last-child {
  padding: 0 0 0 8px !important;
}

.dns-records .record-type-value .record-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 37px;
}

.dns-records .record-type-value .record-value .ant-btn {
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.dns-records .record-type-value .record-value .ant-btn:hover,
.dns-records .record-type-value .record-value .ant-btn:active,
.dns-records .record-type-value .record-value .ant-btn:focus {
  border-left-color: rgb(217, 217, 217);
}

.guide .cert-file,
.monitor .cert-file {
  display: block !important;
  margin-top: 5px !important;
  background-color: #f5f5f5;
  border: none;
}

.ant-drawer-header {
  height: 45px;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 0px;
}

.ant-radio-button-wrapper {
  margin-top: 2px !important;
}

.www {
  height: 100%;
}

.www .area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  min-width: 1280px;
}

.www .form-container {
  width: 350px;
  background: linear-gradient(135deg, white, #F4F6F7);
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 10%), 2px 2px 16px 0px rgb(0 0 0 / 20%), 4px 16px 64px 0px rgb(0 0 0 / 50%);
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 4px 0px, rgba(0, 0, 0, 0.2) 2px 2px 16px 0px;
  z-index: 1;
  padding: 20px;
  opacity: 0.9;
}

.www .signin .form-container {
  height: 445px;
}

.www .signup .form-container {
  height: 550px;
}

.www .forget .form-container {
  height: 335px;
}

.www .reset .form-container {
  height: 335px;
}

.www .oauth-signin .form-container {
  height: 235px;
}

.www .unsubscribe .form-container {
  height: 255px;
}

.www .form-container .oauth {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.www .form-container .oauth img{
  height: 28px;
  margin-right: 8px;
}

.www .form-container .title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 10px;
}

.www .form-container .title h1 {
  font-size: 36px;
}

.www .form-container .title h4 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}

.www .form-container .ant-input {
  border-width: 0 0 1px 0;
  background: transparent;
  font-size: 14px;
}

.www .form-container .ant-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.www .form-container .ant-input:placeholder-shown {
  font-size: 14px;
}

.www .form-container .ant-input:hover {
  border-right: 0px !important;
}

.www .form-container .ant-input:focus {
  border-width: 0 0 1px 0 !important;
  outline: 0;
  box-shadow: none;
}

.www .form-container .ant-btn-link {
  font-size: 12px;
  width: 100%;
  margin-top: 15px !important;
  color: rgba(0, 0, 0, 0.5)
}

.www .form-container .ant-btn-link span {
  text-decoration: underline;
}

.www .default * {
  letter-spacing: 0.15rem;
}

.www .default{
  min-width: 1280px;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.www .default::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.www .default .container .header {
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  max-width: 980px;
  position: relative;
}

.www .default .container .content {
  height: calc(100% - 45px);
  width: 100%;
  max-width: 960px;
}

.www .default .container .header .logo {
  font-size: 20px;
  padding-left: 10px;
}

.www .default .container .header .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
}

.www .default .container .heaer .logo,
.www .default .container .header .menu>div {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  width: 80px;
  text-align: center;
}

.www .default .container .header .menu>div:hover {
  background-color: #2670e8;
}

.www .default .container .content div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white !important;
}

.www .default h1,
.www .default h2,
.www .default h3,
.www .default h4,
.www .default h5 {
  color: white !important;
}

.www .default .container .content>div:nth-child(1) {
  margin: 64px 0;
}

.www .default .container .content>div:nth-child(1) h1 {
  font-size: 48px !important;
  color: white !important;
}

.www .default .container .content>div:nth-child(1) h1:first-child {
  margin-bottom: 10px;
}

.www .default .container .content>div:nth-child(2) {
  margin-top: 20px;
}

.www .default .container .content>div:nth-child(3) {
  margin-top: 80px;
  margin-bottom: 80px;
}

.www .default .container .content>div:nth-child(3) h2 {
  font-size: 24px !important;
}

.www .default .container .content>div:nth-child(4),
.www .default .container .content>div:nth-child(5),
.www .default .container .content>div:nth-child(6),
.www .default .container .content>div:nth-child(7),
.www .default .container .content>div:nth-child(8) {
  margin-bottom: 110px;
  height: 290px;
  flex-direction: row;
  position: relative;
}

.www .default .container .content>div:nth-child(4) div,
.www .default .container .content>div:nth-child(5) div,
.www .default .container .content>div:nth-child(6) div,
.www .default .container .content>div:nth-child(7) div,
.www .default .container .content>div:nth-child(8) div {
  width: 50%;
}

.www .default .container .content>div:nth-child(4) h3,
.www .default .container .content>div:nth-child(5) h3,
.www .default .container .content>div:nth-child(6) h3,
.www .default .container .content>div:nth-child(7) h3,
.www .default .container .content>div:nth-child(8) h3 {
  font-size: 36px;
  align-self: start;
}

.www .default .container .content>div:nth-child(4) p,
.www .default .container .content>div:nth-child(5) p,
.www .default .container .content>div:nth-child(6) p,
.www .default .container .content>div:nth-child(7) p,
.www .default .container .content>div:nth-child(8) p {
  font-size: 14px;
  align-self: start;
  letter-spacing: 0.1rem;
  color: rgba(256, 256, 256, 0.7) !important;
}

.www .default .container .content>div:nth-child(4) .right object,
.www .default .container .content>div:nth-child(6) .right object,
.www .default .container .content>div:nth-child(8) .right object {
  position: absolute;
  left: 577px;
  top: 0px;
}

.www .default .container .content>div:nth-child(5) .left object,
.www .default .container .content>div:nth-child(7) .left object {
  position: absolute;
  left: -121px;
  top: 0px;
}

.www .default .container .content>div:nth-child(4)>div,
.www .default .container .content>div:nth-child(5)>div,
.www .default .container .content>div:nth-child(6)>div,
.www .default .container .content>div:nth-child(7)>div,
.www .default .container .content>div:nth-child(8)>div {
  align-self: flex-start;
  padding-top: 15px;
}

.www .default .container .content object {
  height: 290px;
}

.www .default .container .content>div:last-child {
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
}

.www .default .container .content>div:last-child>div>div>div {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  width: 80px;
  text-align: center;
}

.www .default .container .content>div:last-child>div>div>div:hover {
  background-color: #2670e8;
  cursor: pointer;
}

.www .default .container .content>div:last-child>div{
  width: 50%;
  padding-bottom: 20px;
}

.www .default .container .content>div:last-child>div:nth-child(1){
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
}

.www .default .container .content>div:last-child>div:nth-child(2){
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 280px;
}

.www .default .container .content>div:last-child>div:nth-child(1)>div:nth-child(1){
  align-self: flex-start;
  font-size: 18px;
  font-weight: 600;
}

.www .default .container .content>div:last-child>div:nth-child(1)>div:nth-child(1){
  font-size: 18px;
  font-weight: 600;
}

.www .default .container .content>div:last-child>div:nth-child(1)>div:nth-child(2){
  margin-top: 10px;
  font-size: 12px;
  color:rgba(256, 256, 256, 0.7) !important;
}

.www .default .container .content>div:last-child>div:nth-child(2)>div:nth-child(1){
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
}

.www .default .container .content>div:last-child>div:nth-child(2)>div:nth-child(2),
.www .default .container .content>div:last-child>div:nth-child(2)>div:nth-child(3){
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
}

.www .default .container .content>div a {
  color: white !important;
}

.www .default input:focus {
  box-shadow: none !important;
}

.www .default #stats::before {
  content: ' ';
}

.www .ant-layout-content {
  border-top: 1px solid rgba(0, 0, 0, 0.65);
  height: 100%;
}

.ant-btn,
.ant-input,
.ant-table,
.ant-tag,
.ant-pagination-item,
.ant-select-selection,
.ant-dropdown-menu,
.ant-message-notice-content,
.ant-radio-button-wrapper,
.ant-select-dropdown,
.ant-checkbox-inner,
.ant-input-group-addon,
.ant-transfer-list,
.ant-input-number,
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse-item:last-child > .ant-collapse-content,
.ant-alert {
  border-radius: 0px !important;
}

.ant-table-small>.ant-table-content>.ant-table-body {
  margin: 0px !important;
}

.ant-avatar img {
  background: white;
}

.guide *,
.monitor *,
.ant-drawer *,
.ant-message *,
.ant-dropdown *,
.ant-steps-item *,
.ant-select-dropdown-menu-item {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

textarea.ant-input {
  background: #f5f5f5;
  border: none;
}

.monitor .content.dashboard,
.monitor .content.my-invitations {
  margin-top: 4px;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  height: 2px;
}

.ant-tag {
  margin-top: 1px !important;
}

.dashboard .statistics-container {
  border: 1px solid #dddddd;
  margin-bottom: 10px;
}

.dashboard .statistics-header {
  background: #f9f9f9;
  height: 36px;
  line-height: 36px;
}

.dashboard .statistics-body {
  padding: 10px 0px;
}

.dashboard .right-border {
  border-right: 1px solid #dddddd;
}

.dashboard .statistics-body .right-border:last-child {
  border-right: none;
}

.dashboard .statistic-item-title {
  font-size: 14px !important;
}

.dashboard .statistic-item-value {
  font-size: 30px !important;
}

.dashboard .statistics-aleart-item {
  color: #f50;
}

.dashboard .statistics-success-item {
  color: #52c41a;
}

.dashboard .ant-table-small {
  border: none;
}

.dashboard .ant-table-footer {
  padding: 8px 16px;
}

.dashboard .ant-pagination {
  position: absolute;
  bottom: -4px;
  right: 10px;
}

.dashboard .ant-row,
.dashboard .ant-row-flex {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.dashboard .ant-table-selection-column {
  height: 36px;
}

.ps__rail-x,
.ps__rail-y {
  z-index: 999;
}

.fixed-width {
  max-width: 1024px;
  min-width: 1024px;
  margin: auto;
}

.full-width {
  width: 100%;
}

.bg-blue {
  background: #1890ff;
}

.bg-white {
  color: #fff;
}

.logo-cmp {
  display: inline-block;
}

.logo-cmp .circle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-cmp .circle-in {
  border-radius: 50%;
}

.logo-cmp .circle-in.white {
  background: #1890ff;
}

.logo-cmp .circle-in.blue {
  background: white;
  border-radius: 50%;
}

.logo-cmp .circle-out {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: relative;
}

.logo-cmp .circle-out.white {
  border: 1px solid white;
  background: white;
}

.logo-cmp .circle-out.blue {
  border: 1px solid #1890ff;
  background: #1890ff;
}

.logo-cmp .circle-out::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}

.logo-cmp .circle-out.white::after {
  border: 1px solid white;
}

.logo-cmp .circle-out.blue::after {
  border: 1px solid #1890ff;
}

.header-cmp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.header-cmp .logo-cmp {
  margin-bottom: 2px;
}

.header-cmp span {
  margin-left: 3px;
  font-size: 20px !important;
}

.header-cmp span.blue {
  color: #1890ff
}

.header-cmp span.white {
  color: white;
}

.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: normal !important;
}

.ant-tabs-ink-bar {
  height: 1px !important;
}

.detail .header {
  font-size: 24px !important;
  text-align: left;
  margin-bottom: 25px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.detail .header i {
  margin-right: 5px;
}

.detail .header i svg {
  font-size: 24px !important;
}

.detail .header .ant-btn {
  height: 24px;
  line-height: 24px;
  padding: 0px;
}

.detail .header .ant-btn span {
  font-size: 24px !important;
}

.ant-modal-title,
.ant-modal-confirm-title {
  font-size: 14px !important;
}

.ant-modal-body,
.ant-modal-confirm-content {
  font-size: 12px !important;
}

.ant-modal-body .anticon,
.ant-modal-confirm-body .anticon {
  font-size: 20px !important;
}

.ant-modal-header {
  padding: 13px 24px !important;
}

.charge-modal .ant-modal-header,
.charge-modal .ant-modal-footer {
  border: none;
}

.ant-modal-footer button,
.ant-modal-confirm-btns button {
  margin-left: 20px !important;
  padding-left: 20px;
  padding-right: 20px;
}

.ant-modal-content {
  border-radius: 0px !important;
}

.ant-modal-body {
  padding: 20px 20px 15px !important;
}

.charge-modal .ant-modal-body {
  padding: 15px 20px 15px !important;
}

.ant-modal-body * {
  font-size: 12px !important;
  cursor: pointer;
}

.ant-modal-body .ant-pagination .ant-pagination-options .ant-select-arrow .anticon {
  font-size: 12px !important;
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-left: 28px !important;
}

.ant-btn-primary {
  background-color: #0052d9 !important;
  border-color: #0052d9 !important;
  color: white !important;
}

.ant-alert-info {
  background-color: #d5e7ff !important;
  border: 1px solid #d5e7ff !important;
  color: #002da0 !important;
}

.ant-alert-info .ant-alert-close-icon,
.ant-alert-info .ant-alert-message {
  color: #002da0 !important;
}

.ant-layout-sider {
  background-color: #1e222d !important;
}

.ant-layout-sider-trigger {
  background-color: #181818 !important;
  position: absolute !important;
}

.invitation-area {
  background-color: #d5e7ff !important;
  border: 1px solid #d5e7ff !important;
  color: #002da0 !important;
}

.invitation-area .ant-input-group-addon,
.invitation-area .ant-input {
  border: none;
}

.ant-badge-status-text {
  color: #002da0 !important;
}

.tag-success-line,
.tag-info-line {
  border: none;
}

.tucao {
  height: 45px;
  position: relative;
}

.tucao form {
  display: flex;
}

.tucao button {
  padding: 0px;
  border: none;
  background-color: transparent;
  height: 45px;
  line-height: 45px;
  font-weight: 600;
}

.tucao button:focus {
  outline: none;
}

.tucao button:active {
  border-style: none;
}

.tucao button:hover {
  cursor: pointer;
}

.ant-transfer {
  white-space: nowrap;
}

.menu {
  height: 45px;
  position: relative;
  padding: 0px;
  border: none;
  margin-left: 18px;
  background-color: transparent;
  height: 45px;
  line-height: 45px;
}

.menu:focus {
  outline: none;
}

.menu:active {
  border-style: none;
}

.menu:hover {
  cursor: pointer;
}

.markdown img {
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
}

.monitor .ant-collapse,
.guide .ant-collapse {
  border-radius: 0 !important;
  background: white;
}

.ant-table-scroll .ant-table-body[style='overflow-x: scroll;'] {
  overflow-x: auto !important;
}

.ant-table-row .ant-btn-link {
  height: 17px;
}

.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #0052d9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #0052d9 !important;
  background-color: #0052d9 !important;
}

.ant-switch-checked {
  background-color: #0052d9 !important;
}

.monitor .ant-layout-sider {
  height: 100%;
}

.monitor .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.5em;
}

.monitor .ant-table-tbody>tr>td.ant-table-column-sort,
.monitor .ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters:hover,
.monitor .ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters:hover::before,
.monitor .ant-table-small>.ant-table-content>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort,
.monitor .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th.ant-table-column-sort,
.monitor .ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort,
.monitor .ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-thead>tr>th.ant-table-column-sort,
.monitor .ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort,
.monitor .ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-thead>tr>th.ant-table-column-sort,
.monitor .ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th.ant-table-column-sort,
.monitor .ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th.ant-table-column-sort {
  background: none;
}

.ant-layout-sider-trigger {
  height: 40px !important;
  line-height: 40px !important;
}

.domain-tag {
  background: transparent;
  margin-right: 10px;
  padding-bottom: 1px;
  border-bottom: 1px solid;
  color: #1890ff !important;
  display: inline-block;
}

::-webkit-scrollbar {
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.12);
}

.www .context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.www .context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.www .area {
  background: rgb(242, 243, 244);
  width: 100%;
  height: 100vh;
  position: relative;
}

.www .area .title {
  cursor: pointer;
}

.www .circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

.www .circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgb(0, 82, 217);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.www .circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.www .circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.www .circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.www .circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.www .circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.www .circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.www .circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.www .circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.www .circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.www .circles li:nth-child(10) {
  left: 85%;
  width: 110px;
  height: 110px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.tencent-captcha-dy__opera-area .tencent-captcha-dy__slider-block{
  border-radius: 0px !important;
}
